var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ar" }, [
    _vm.isUploading ? _c("div", { staticClass: "ar__overlay" }) : _vm._e(),
    _vm._v(" "),
    _vm.isUploading
      ? _c("div", { staticClass: "ar-spinner" }, [
          _c("div", { staticClass: "ar-spinner__dot" }),
          _vm._v(" "),
          _c("div", { staticClass: "ar-spinner__dot" }),
          _vm._v(" "),
          _c("div", { staticClass: "ar-spinner__dot" })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ar-content", class: { ar__blur: _vm.isUploading } },
      [
        _c(
          "div",
          { staticClass: "ar-recorder" },
          [
            _c("icon-button", {
              staticClass: "ar-icon ar-icon__lg",
              class: {
                "ar-icon--rec": _vm.isRecording,
                "ar-icon--pulse": _vm.isRecording && _vm.volume > 0.02
              },
              attrs: { name: _vm.iconButtonType },
              nativeOn: {
                click: function($event) {
                  return _vm.toggleRecorder($event)
                }
              }
            }),
            _vm._v(" "),
            !_vm.minimalMode
              ? _c("icon-button", {
                  staticClass: "ar-icon ar-icon__sm ar-recorder__stop",
                  attrs: { name: "stop" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.stopRecorder($event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.attempts && !_vm.minimalMode
          ? _c("div", { staticClass: "ar-recorder__records-limit" }, [
              _vm._v(
                "Attempts: " +
                  _vm._s(_vm.attemptsLeft) +
                  "/" +
                  _vm._s(_vm.attempts)
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.countdown
          ? _c("div", { staticClass: "ar-recorder__time-remaining" }, [
              _vm._v(_vm._s(_vm.countdownTitle))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "ar-recorder__duration" }, [
          _vm._v(_vm._s(_vm.recordedTime))
        ]),
        _vm._v(" "),
        _vm.time && !_vm.minimalMode
          ? _c("div", { staticClass: "ar-recorder__time-limit" }, [
              _vm._v("Record duration is limited: " + _vm._s(_vm.time) + "s")
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.minimalMode
          ? _c(
              "div",
              { staticClass: "ar-records" },
              _vm._l(_vm.recordList, function(record, idx) {
                return _c(
                  "div",
                  {
                    key: record.id,
                    staticClass: "ar-records__record",
                    class: {
                      "ar-records__record--selected":
                        record.id === _vm.selected.id
                    },
                    on: {
                      click: function($event) {
                        return _vm.choiceRecord(record)
                      }
                    }
                  },
                  [
                    record.id === _vm.selected.id
                      ? _c(
                          "div",
                          {
                            staticClass: "ar__rm",
                            on: {
                              click: function($event) {
                                return _vm.removeRecord(idx)
                              }
                            }
                          },
                          [_vm._v("×")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "ar__text" }, [
                      _vm._v("Record " + _vm._s(idx + 1))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ar__text" }, [
                      _vm._v(_vm._s(record.duration))
                    ]),
                    _vm._v(" "),
                    record.id === _vm.selected.id && _vm.showDownloadButton
                      ? _c("downloader", {
                          staticClass: "ar__downloader",
                          attrs: { record: record, filename: _vm.filename }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    record.id === _vm.selected.id && _vm.showUploadButton
                      ? _c("uploader", {
                          staticClass: "ar__uploader",
                          attrs: {
                            record: record,
                            filename: _vm.filename,
                            headers: _vm.headers,
                            "upload-url": _vm.uploadUrl
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("audio-player", {
          class: {
            disabled: _vm.selected === {} || _vm.selected === undefined
          },
          attrs: { record: _vm.selected }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ab2a3464", { render: render, staticRenderFns: staticRenderFns })
  }
}